  
.map-container {
    height: 600px;
    width: 1300px;
    padding: 50px;
    margin: auto;
    }

.map {
    height: 700px;
    width: 1200px;
    margin: auto;
    }